console.info('Vite ⚡️ Rails');

import './style.css';
import '../controllers';
import * as Turbo from '@hotwired/turbo';
Turbo.start();

import { inputValidationListener } from '../components/profiles/input-validation.js';
import { createApp } from 'vue';
import Search from '../components/profiles/search.vue';

export function resumeSubmitButton() {
  const form = document.getElementById('resume_form');
  const check = document.getElementById('checkbox');

  if (form !== null) {
    if (check == null || check.checked) {
      form.submit();
    } else {
      document.getElementById('error_message').classList.remove('tw-hidden');
    }
  }
}

document.addEventListener('turbo:load', function () {
  globalSearchListener();
  toggleOverflowMenu();
  sortSelect();
  closeFlashMessage();
  closeModal();
  checkCookie();
  emailConfirmation();
  searchAutoComplete();
});

const getAttributeById = (id, attribute) => {
  const element = document.getElementById(id);
  return element && element.getAttribute(attribute);
};

document.addEventListener('turbo:load', () => {
  inputValidationListener();

  const mounts = [
    { selector: '#search', component: Search },
    { selector: '#search_box_sidebar', component: Search },
  ];

  const isEnglishLocale = getAttributeById('search', 'data') === 'en' || getAttributeById('search_box_sidebar', 'data') === 'en';

  mounts.forEach(({ selector, component }) => {
    if (component) {
      createApp(Search, {
        search: isEnglishLocale ? 'Search for resources on ApprenticeSearch.com' : 'Rechercher des ressources sur ApprenticeSearch.com',
      }).mount(selector);
    }
  });

  const resumeSubmitButtonElement = document.getElementById('resume_submit_button');
  if (resumeSubmitButtonElement) {
    resumeSubmitButtonElement.addEventListener('click', (event) => {
      event.preventDefault();
      resumeSubmitButton();
    });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('profile_resume')) {
    document.getElementById('profile_resume').onchange = function () {
      document.getElementById('resume_form').submit();
    };
  }
});

export function checkCookie() {
  let cookieEnabled = navigator.cookieEnabled;
  if (cookieEnabled) {
    return true;
  } else {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;

    if (!cookieEnabled) {
      return showCookieFail();
    } else {
      document.getElementById('cookieModal').style.display = 'none';
      return true;
    }
  }
}

function showCookieFail() {
  function openModal() {
    document.getElementById('cookieModal').style.display = 'block';
  }
  function closeModal() {
    document.getElementById('cookieModal').style.display = 'none';
  }
  function checkCookie() {
    const cookieEnabled = navigator.cookieEnabled;
    if (!cookieEnabled) {
      openModal();
    }
  }
  window.onload = function () {
    checkCookie();
    document.querySelector('.close-cookie-message').addEventListener('click', closeModal);
  };
  return false;
}

const closeFlashMessage = () => {
  const closeLinks = document.querySelectorAll('.flash_message a#close');
  closeLinks.forEach((link) => {
    link.addEventListener('click', () => {
      const flashMessage = link.closest('.flash_message');
      if (flashMessage) {
        flashMessage.style.display = 'none';
      }
    });
  });
};

export function closeModal(message) {
  if (document.getElementById('modal-close')) {
    const modals = document.querySelectorAll('.modal');
    const closeButton = document.getElementById('modal-close');
    const background = document.getElementById('modal-background');
    const close = () => {
      if (message && message.classList.contains('unread') && document.querySelector('.modal.is-active')) {
        modals.forEach((modal) => {
          modal.classList.remove('is-active');
        });
        setTimeout(() => {
          location.href = location.href;
        }, 10);
      } else {
        modals.forEach((modal) => {
          modal.classList.remove('is-active');
        });
      }
    };
    closeButton.addEventListener('click', close);
    background.addEventListener('click', close);
    document.onkeydown = function (event) {
      event = event || window.event;
      let isEscape = false;
      if ('key' in event) {
        isEscape = event.key === 'Escape' || event.key === 'Esc';
      } else {
        isEscape = event.keyCode === 27;
      }
      if (isEscape) {
        close();
      }
    };
  }
}

const sortSelect = () => {
  if (document.getElementById('sort')) {
    const sort = document.getElementById('sort');
    sort.addEventListener('change', () => {
      document.forms['sort'].dispatchEvent(new Event('submit', { bubbles: true }));
    });
  }
};

const toggleOverflowMenu = () => {
  const $ellipsis = Array.prototype.slice.call(document.querySelectorAll('.v-ellipsis'), 0);

  if ($ellipsis.length > 0) {
    $ellipsis.forEach((el) => {
      el.addEventListener('click', () => {
        const target = el.dataset.target;
        const $target = document.getElementById(target);
        $target.classList.toggle('is-active');
        el.style.display = 'none';
      });
    });
  }
};

const globalSearchListener = () => {
  const icon = document.getElementById('globalSearchIcon');
  const searchBar = document.getElementById('globalSearch');
  if (icon !== null) {
    icon.addEventListener('click', () => {
      searchBar.style.display = 'inline-block';
    });
  }
};

const emailConfirmation = () => {
  const myInput = document.getElementById('email_confirmation');
  if (myInput) {
    myInput.onpaste = (e) => e.preventDefault();
  }
};

const searchAutoComplete = () => {
  const form = document.getElementById('trade-search-form');
  if (!form) {
    return;
  }
  const words = JSON.parse(document.getElementById('trades-list').dataset.data);
  const autocomplete = document.getElementById('autocomplete');
  const search = document.getElementById('search_');
  let match = '';
  search.addEventListener('keyup', () => {
    if (search.value.length > 0) {
      const input = search.value;
      autocomplete.innerHTML = input;

      const regex = new RegExp('^' + input + '.*', 'i');

      for (let i = 0; i < words.length; i++) {
        if (words[i].match(regex)) {
          match = words[i];
          autocomplete.innerHTML += words[i].slice(input.length, words[i].length);
          break;
        }
      }
    }
  });
  search.addEventListener('keydown', (e) => {
    if (search.value.length <= 1 && e.key === 'Backspace') {
      autocomplete.innerHTML = '';
    } else if (e.key === 'Tab' && match.length > 0) {
      search.value = match;
      match = '';
      autocomplete.innerHTML = '';
      form.submit();
    }
  });
};
